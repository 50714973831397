body{
    color: var(--link_color) !important;
    background-color: var(--body_background) !important;
}
img{
    max-width: 100%;
}
main{
    margin: 0 auto;
}

a{
    text-decoration:none !important;
    color: inherit !important;
}
.mt-12{
    margin-top: 7.5rem!important;
  }
.gap-20{
    gap: 20px;
}
.margin-auto{
    margin: auto;
}
.blog-post-button{
    font-size: 12px;
    color: #fff !important;
    background-color: #F11A7B;
    padding: 12px 30px;
    display: inline-block;
    border-radius: 25px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.5px;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    width: 100%;
    text-align: center;
}

.module-border-wrap {
    max-width: 250px;
    padding: 1rem;
    position: relative;
    background: linear-gradient(to right, red, purple);
    padding: 3px;
  }
  
  .module {
    background: #222;
    color: white;
    padding: 2rem;
  }
